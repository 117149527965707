import { useAPI } from '@/api/api';
import { useSunburstStore } from '@/store/sunburst';

export function useSunburstAPI() {
  return {
    requestData() {
      const withSunburstStore = useSunburstStore();
      return useAPI(withSunburstStore, 'dataRequest')
        .get('/sunburst')
    },
  };
}
