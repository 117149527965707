import { useAPI } from '@/api/api';
import { useVariantsStore } from '@/store/variants';

export function useVariantAPI() {
  return {
    requestVariant(variantId) {
      const withVariantsStore = useVariantsStore();
      return useAPI(withVariantsStore, 'variantRequest').get(`/variants/${variantId}`);
    },
  };
}
