import { defineStore } from 'pinia';
import { useOncoprintAPI } from '@/api/oncoprint';
import { useVariantAPI } from '@/api/variant';

export const useVariantsStore = defineStore('variants', {
  state: () => ({
    selectedDiseases: [],
    oncoprintRequest: {},
    variantRequest: {},
  }),
  getters: {
    variant() {
      return this.variantRequest.response?.data?.data;
    },
  },
  actions: {
    requestOncoprintData(diseaseHierarchy) {
      this.oncoprintRequest.request = useOncoprintAPI().requestData(diseaseHierarchy);
    },
    clearOncoprintData() {
      this.oncoprintRequest = {};
    },
    requestVariantData(variantId) {
      return useVariantAPI().requestVariant(variantId);
    },
    clearVariant() {
      this.variantRequest = {};
    },
    selectDiseases(diseases) {
      this.selectedDiseases = diseases;
    },
    clearSelections() {
      this.selectedDiseases = [];
    },
  },
});
