<template>
  <header class="relative z-50">
    <div class="sjc-omnibar">
      <nav class="navbar">
        <a
          class="navbar-brand"
          href="https://stjude.cloud"
        >
          <span class="logo" />
        </a>
        <a
          class="sjc-title"
          href="https://stjude.cloud"
        >St. Jude Cloud</a>
        <a
          class="portal-title"
          href="/"
        >PeCan</a>

        <ul class="nav global-icons">
          <li class="d-flex align-items-center">
            <AuthButtons />
          </li>

          <!-- TODO: Uncomment the user dropdown menu below when we'll have something to show in there -->
          <!-- <li v-if="false"-->
          <!--   class="nav-item user-dropdown dropdown"-->
          <!--   :class="[showUserDropdown ? 'show' : false]"-->
          <!-- >-->
          <!--   <a-->
          <!--     class="nav-link dropdown-toggle profile-dropdown-toggle"-->
          <!--     href=""-->
          <!--     role="button"-->
          <!--     aria-haspopup="true"-->
          <!--     aria-expanded="false"-->
          <!--     title="User Info"-->
          <!--     @click.prevent="showUserDropdown = !showUserDropdown"-->
          <!--   >-->
          <!--     <span class="user-icon"></span>-->
          <!--   </a>-->
          <!--   <div-->
          <!--     class="dropdown-menu dropdown-menu-right profile-dropdown-menu"-->
          <!--     aria-labelledby="profile-dropdown-toggle"-->
          <!--   >-->
          <!--     <ul class="list-unstyled">-->
          <!--       <li>-->
          <!--         <a class="dropdown-item" href="#" @click="logout"> Sign out </a>-->
          <!--       </li>-->
          <!--     </ul>-->
          <!--   </div>-->
          <!-- </li>-->

          <li
            class="nav-item dropdown mega-dropdown"
            :class="[showMegaDropdown ? 'show' : false]"
          >
            <a
              class="nav-link"
              href=""
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              title="St. Jude Cloud Mega Menu"
              @click.prevent="showMegaDropdown = !showMegaDropdown"
            >
              <div class="nav-menu-icon" />
            </a>
            <div
              :class="['absolute dropdown-menu mega-dropdown-menu', showMegaDropdown ? 'show' : false]"
              aria-labelledby="mega-dropdown-toggle"
            >
              <MegaMenu />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue';

import AuthButtons from '@/components/auth/AuthButtons';
import MegaMenu from '@/layouts/MegaMenu';

let showMegaDropdown = ref(false);
</script>
