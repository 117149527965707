<template>
  <template v-if="!isAuthenticated">
    <LoginButton />
  </template>
  <template v-if="isAuthenticated">
    <LogoutButton />
  </template>
</template>

<script setup>
import { onMounted } from 'vue';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

import { useAuth0 } from '@auth0/auth0-vue';

const { isAuthenticated, checkSession } = useAuth0();

onMounted(async () => await checkSession());
</script>