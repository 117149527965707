<template>
  <div class="px-2 md:px-2 ml-auto mr-0 sm:mr-[20px] my-[12px] w-full md:w-auto">
    <div
      class="h-full flex flex-row transition rounded-t-md"
      :class="[
        darkMode && onHover ? 'outline outline-blue-50/25' : 'outline-0',
        darkMode && inFocus ? 'outline outline-blue-50/25' : 'outline-0',
        onHover ? 'bg-white outline outline-blue-100 outline-2' : '',
        results.length && isOpen ? '' : 'rounded-b-md',
        inFocus ? 'outline outline-blue-100 outline-2' : ''
      ]"
      @mouseover="onHover = true"
      @mouseleave="onHover = false"
    >
      <Listbox
        v-slot="{ open }"
        :model-value="tab"
        @update:model-value="(value) => switchTabTo(value)"
      >
        <div class="relative">
          <ListboxButton
            class="relative h-full w-[105px] cursor-default pt-[10px] pb-[9px] pl-[10px] flex flex-row justify-between rounded-tl-md border-[1px] transition"
            :class="[
              darkMode && !onHover ? 'border-[#214874] text-[#9EC0E7]' : 'border-neutral-300 text-neutral-700',
              inFocus ? 'border-blue-primary' : '',
              open ? 'bg-white' : 'rounded-bl-md',
              results.length && isOpen ? 'rounded-bl-none' : '',
            ]"
          >
            <div class="uppercase text-[10px] font-bold tracking-widest transition">
              {{ tab }}
            </div>
            <div class="pointer-events-none flex items-center px-2">
              <font-awesome-icon
                icon="caret-down"
                class="h-3 w-3 transition"
                :class="[
                  darkMode && !onHover ? 'text-[#3A89E1]' : 'text-neutral-400',
                  inFocus ? 'text-blue-primary' : '',
                ]"
              />
            </div>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute p-[8px] w-[200px] rounded-b-md bg-white text-[#333] text-[10px] font-bold border-b-[1px] border-l-[1px] border-r-[1px] border-neutral-300 z-10"
            >
              <ListboxOption
                v-for="option in ['diagnosis', 'gene', 'variant']"
                v-slot="{ active }"
                :key="option"
                :value="option"
                as="template"
              >
                <li
                  class="p-[8px] tracking-widest"
                  :class="[active ? 'bg-blue-50 text-blue-primary rounded' : '', 'cursor-pointer']"
                >
                  <span class="uppercase">{{ option }}</span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <div
        class="h-full w-full my-auto flex flex-row justify-between rounded-tr-md border-r-[1px] border-y-[1px] transition relative"
        :class="[
          darkMode && !onHover ? 'border-[#214874]' : 'border-neutral-300',
          inFocus ? 'border-blue-primary' : '',
          results.length && isOpen ? 'rounded-br-none' : 'rounded-br-md',
        ]"
      >
        <input
          id="navSearch"
          type="text"
          name="navSearch"
          placeholder="Search"
          :value="searchTerm"
          class="mx-[10px] my-[5px] outline-none text-neutral-700 transition w-full"
          :class="{ 'bg-content-dark-blue text-white': darkMode && !onHover }"
          :readonly="loading"
          @input="debouncedSearch"
          @keydown.down.prevent="onKeyDown"
          @keydown.up.prevent="onKeyUp"
          @keydown.enter="onEnter"
          @focus="inFocus = true"
          @blur="inFocus = false"
        >
        <svg
          v-if="loading"
          class="animate-spin w-[16px] h-[16px] my-auto mr-[10px] text-neutral-400 absolute right-0 top-[8px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <div
          v-else
          class="my-auto mr-[10px] text-neutral-400"
        >
          <button
            v-if="searchTerm"
            class="text-[14px] align-middle flex flex-row"
            @click="resetSearch"
          >
            <div class="mr-[4px]">
              Clear
            </div>
            <font-awesome-icon
              icon="times"
              class="text-neutral-300 mt-auto"
            />
          </button>
          <font-awesome-icon
            v-else
            icon="search"
          />
        </div>
      </div>
    </div>

    <div class="w-full ml-auto z-10 relative">
      <ul
        v-show="results.length && isOpen"
        class="bg-white rounded-b-lg border border-neutral-300"
      >
        <li
          v-for="(result, index) in results"
          :key="index"
          class="px-[12px] py-[8px] cursor-pointer last:rounded-b-lg border-b"
          :class="
            index === hoveredResultIndex
              ? 'bg-neutral-200 text-blue-primary'
              : 'hover:bg-neutral-200 hover:text-blue-primary'
          "
          @click="goToResult(result)"
          @mouseover="hoveredResultIndex = -1"
        >
          <div
            v-if="result.type === 'variant'"
            class="flex flex-row justify-between text-[14px]"
          >
            <div class="my-auto">
              {{ result.gene_symbol }}
              <span class="font-bold">{{ result.aachange }}</span>
              <span
                v-if="result.duplicateIndex > 1"
                class="text-neutral-400 ml-1 font-bold"
              >
                ({{ result.duplicateIndex }})
              </span>
            </div>
            <div class="text-neutral-600 text-[10px] tracking-widest font-bold flex items-center">
              <div
                v-if="result.class"
                class="bg-neutral-100 flex flex-row py-[5px] rounded"
              >
                <span
                  class="w-[10px] h-[10px] mx-1 my-auto rounded block"
                  :class="variantClassBackgrounds[result.class.toLowerCase()]"
                />
                <span class="mr-1 uppercase">{{ result.class }}</span>
              </div>
            </div>
          </div>
          <div v-else-if="result.type === 'gene'">
            <div class="flex flex-row justify-between">
              <div>
                {{ result.legacy_text }}
              </div>
              <div
                class="flex flex-row text-[9px] font-bold tracking-widest text-[#0A496B] uppercase my-auto border-[1px] border-neutral-300 rounded p-[8px]"
              >
                <img
                  :srcset="`/images/variants/ProteinPaint.png 1x, /images/variants/ProteinPaint@2x.png 2x`"
                  :src="`/images/variants/ProteinPaint.png`"
                  class="w-[16px] h-[12px] mr-[6px]"
                >
                View in ProteinPaint
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-row text-neutral-700">
              <div class="w-[200px] text-[12px] my-auto">
                {{ result.dx_full }}
              </div>
              <div
                class="flex items-center justify-center text-[12px] font-bold w-[30px] h-[24px] m-auto border-[1px] rounded-md"
                :class="getTumorColor(result.ancestor_dx)"
              >
                {{ tumorType(result.ancestor_dx) }}
              </div>
              <div class="my-auto ml-[15px] mr-[30px]">
                <div class="text-[9px] text-neutral-500 tracking-widest uppercase font-bold">
                  Samples
                </div>
                <div class="text-[12px] text-neutral-700">
                  {{ result.total_sample_count }}
                </div>
              </div>
              <div class="my-auto">
                <div class="text-[9px] text-neutral-500 tracking-widest uppercase font-bold">
                  Patients
                </div>
                <div class="text-[12px] text-neutral-700">
                  {{ result.total_subject_count }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, toRef } from 'vue';
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { useVariantsStore } from '@/store/variants';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';

const props = defineProps({
  darkMode: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  results: {
    type: Array,
    required: true
  },
  searchTerm: {
    type: String,
    default: '',
  },
  tab: {
    type: String,
    required: true,
    default: 'diagnosis',
  },
});

const emit = defineEmits(['open-membrane-overlay', 'reset-search', 'search', 'switch-tab']);

const router = useRouter();

const variantsStore = useVariantsStore();

const variantClassBackgrounds = {
  missense: 'bg-[#3987CC]',
  frameshift: 'bg-[#DB3D3D]',
  nonsense: 'bg-[#FF7F0E]',
  proteindel: 'bg-[#7F7F7F]',
  proteinins: 'bg-[#8C564B]',
  splice: 'bg-[#6633FF]',
  splice_region: 'bg-[#9467BD]',
  silent: 'bg-[#519D3E]',
  utr_3: 'bg-[#998199]',
  utr_5: 'bg-[#819981]',
  intron: 'bg-[#656565]',
  exon: 'bg-black',
};

const isOpen = ref(false);
const hoveredResultIndex = ref(-1);
const onHover = ref(false);
const inFocus = ref(false);

const tab = toRef(props, 'tab');

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = () => {
  isOpen.value = false;
  hoveredResultIndex.value = -1;
};

const switchTabTo = (newTab) => {
  emit('switch-tab', newTab);
  emit('reset-search');
};

const search = (event) => {
  isOpen.value = true;
  emit('search', event.target.value);
};

const debouncedSearch = _.debounce(search, 1000);

const resetSearch = () => {
  emit('reset-search');
  isOpen.value = false;
};

const goToResult = (result) => {
  if (props.tab === 'gene') {
    // Gene centric user flow - clear any variant existing in the store
    variantsStore.clearVariant();

    if (result) router.push({ path: '/variants/proteinpaint', query: { gene: result.id } });
    else if (props.searchTerm) router.push({ path: '/variants/proteinpaint', query: { gene: props.searchTerm } });
  } else if (props.tab === 'variant' && result?.id) {
    router.push({ name: 'variant_page', params: { selectedTab: 'details', variantId: result.id } });
  } else if (props.tab === 'diagnosis') {
    for (const title of document.querySelectorAll('title')) {
      if (title.textContent === result.dx_full) {
        var evt = new MouseEvent('click');
        title.parentNode.dispatchEvent(evt);
        emit('open-membrane-overlay');
        return;
      }
    }
  }
  resetSearch();
};

const onKeyDown = () => {
  if (hoveredResultIndex.value < props.results.length - 1) {
    hoveredResultIndex.value++;
  }
};

const onKeyUp = () => {
  if (hoveredResultIndex.value > 0) {
    hoveredResultIndex.value--;
  }
};

const onEnter = () => {
  goToResult(props.results[hoveredResultIndex.value]);
};

const getTumorColor = (dxPath) => {
  switch (tumorType(dxPath)) {
    case 'BT':
      return 'text-sunburst-bt border-sunburst-bt';
    case 'GCT':
      return 'text-sunburst-gct border-sunburst-gct';
    case 'HM':
      return 'text-sunburst-hm border-sunburst-hm';
    case 'ST':
      return 'text-sunburst-st border-sunburst-st';
  }
};

const tumorType = (dxPath) => {
  return dxPath.split('|')[0];
};
</script>
