<template>
  <div :style="index - hasMiddle >= 0 ? `&#45;&#45;i: ${index}` : null">
    <!-- This is a transparent pixel -->
    <img
      v-if="!facet.src"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    >

    <button
      v-else
      :class="[
        'relative inline-flex flex-col overflow-visible transition ease-in-out',
        disabled ? 'opacity-40':''
      ]"
      :disabled="disabled"
      @click="handleFacetClick"
    >
      <img
        :src="`/images/navbar/data-facets/${facet.src}.svg`"
        :alt="facet.title"
        class="border-3 hover:border-4 ring-4 hover:ring-8 ring-transparent hover:ring-white/10 ring-offset-0 opacity-80 hover:opacity-100 mx-auto transition ease-in-out border rounded-full"
      >

      <div class="fixed inline-flex flex-col space-y-1 overflow-visible inline-block absolute top-[94px] left-1/2 transform -translate-x-1/2 font-bold text-sm text-center text-white w-[160px]">
        <span>
          {{ facet.title }}
        </span>

        <span
          v-if="facet.comingSoon"
          class="pt-[5px]"
        >
          <span class="rounded-full px-2.5 py-0.5 bg-[#fff] bg-opacity-20 font-bold text-[12px] text-white">Coming Soon</span>
        </span>

        <span
          v-else
          class="opacity-60 text-xs tracking-wider uppercase"
        >{{ facet.sampleCount() }} samples</span>
      </div>
    </button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const emit = defineEmits(['click']);

const props = defineProps({
  facet: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  hasMiddle: {
    type: [Boolean, Number],
    required: true,
  },
});

const router = useRouter();

const disabled = computed(() => !props.facet.sampleCount || !props.facet.sampleCount() || props.facet.comingSoon);

function handleFacetClick() {
  if (disabled.value) {
    return;
  }

  emit('click', props.facet.to)
  router.push(props.facet.to());
}
</script>
