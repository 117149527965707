import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import router from '@/router';
import App from '@/App';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { auth0 } from '@/auth';

import './styles/app.css';

if ('development' !== process.env.NODE_ENV) {
  // Datadog client tokens are visible in the code regardless if here or
  // provided via config. If they need to be rotated, it will be just as easy
  // if they are colocated here.
  const DATADOG_APPLICATION_ID = 'de1d878c-c907-41e5-80d3-76d055ef5bf8';
  const DATADOG_CLIENT_TOKEN = 'pub3e1f15a27c697124d767a9328f8913e8';

  // Ref: https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',

    // eslint-disable-next-line no-undef
    service: config.VUE_APP_DD_SERVICE || process.env.VUE_APP_DD_SERVICE,
    // eslint-disable-next-line no-undef
    env: config.VUE_APP_DD_ENV || process.env.VUE_APP_DD_ENV,
    // eslint-disable-next-line no-undef
    version: config.VUE_APP_DD_VERSION || process.env.VUE_APP_DD_VERSION,

    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',

    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  });

  datadogRum.startSessionReplayRecording();

  // Initialized logs sent to datadog.
  // Ref: https://docs.datadoghq.com/logs/log_collection/javascript/#usage
  //
  // Example:
  // import { datadogLogs } from '@datadog/browser-logs';
  //
  // // First arg is the string message and second is a context object.
  // datadogLogs.logger.debug('message', {});
  // datadogLogs.logger.info('message', {});
  // datadogLogs.logger.warn('message', {});
  // datadogLogs.logger.error('message', {});
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',

    // eslint-disable-next-line no-undef
    service: config.VUE_APP_DD_SERVICE || process.env.VUE_APP_DD_SERVICE,
    // eslint-disable-next-line no-undef
    env: config.VUE_APP_DD_ENV || process.env.VUE_APP_DD_ENV,
    // eslint-disable-next-line no-undef
    version: config.VUE_APP_DD_VERSION || process.env.VUE_APP_DD_VERSION,

    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,

    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  });
}

library.add(fab, far, fas);

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target)) && binding.value) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('FontAwesomeLayersText', FontAwesomeLayersText);
app
  .use(router)
  .use(pinia)
  .use(auth0);

// Vue Router 4+ navigation is now asynchronous. To be able to access params
// in our setup or mounted hooks inside of components, we'll first need to
// make sure we wait for those to be ready before mounting our app.
router.isReady().then(() => app.mount('#app'));
