import { useAPI } from '@/api/api';
import { useVariantsStore } from '@/store/variants';

export function useOncoprintAPI() {
  return {
    requestData(diseaseHierarchy) {
      const withVariantsStore = useVariantsStore();
      return useAPI(withVariantsStore, 'oncoprintRequest')
        .get(`/variants/oncoprint/${diseaseHierarchy}`);
    },
  };
}
