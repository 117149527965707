<template>
  <button
    class="relative btn btn-outline-light login-btn"
    @click="handleLogout"
  >
    Sign Out
  </button>
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { logout } = useAuth0();

const handleLogout = async () =>
  await logout({
    logoutParams: {
      returnTo: window.location.origin
    }
  });
</script>