<template>
  <!--
  // Dark navbar with membrane overlay
  //------------------------------------------------------------------------------ -->

  <div
    id="darkNavbar"
    class="z-[10] absolute w-full opacity-0"
  >
    <div class="flex flex-col md:flex-row">
      <!-- Left of the membrane button -->
      <div class="bg-content-dark-blue flex flex-row w-full md:w-5/12 h-[60px] px-0 md:px-[14px] border-b md:border-none border-b-blue-500-alt">
        <router-link
          v-for="(menuItem, index) in menuItems"
          :key="`dark-menu-item-${index}`"
          :to="menuItem.to()"
          :class="[menuItem.isActive() ? 'bg-blue-500-alt' : 'transition', 'px-[14px] w-1/3 md:w-auto h-full inline-flex align-items-center text-md text-white justify-center hover:no-underline hover:bg-blue-500-alt']"
        >
          {{ menuItem.title }}
        </router-link>
        <a
          href="https://university.stjude.cloud/docs/pecan/"
          target="_blank"
          class="px-[14px] w-1/3 md:w-auto h-full inline-flex align-items-center justify-center text-md text-white hover:no-underline hover:bg-blue-content-dark transition-all ease-in-out hover:bg-blue-500-alt"
        ><span class="text-ellipsis overflow-hidden ...">Documentation</span></a>
      </div>

      <!-- Right of the membrane button -->
      <div class="flex flex-row w-full md:w-5/12 h-[60px] bg-content-dark-blue md:order-3">
        <NavSearch
          dark-mode
          :loading="loadingResults"
          :tab="tab"
          :search-term="searchTerm"
          :results="results"
          @switch-tab="switchTabTo"
          @reset-search="resetSearch"
          @search="search"
          @open-membrane-overlay="openMembraneOverlay"
        />
      </div>

      <!-- Dark membrane button -->
      <div class="bg-content-dark-blue relative top-[-10px] md:top-0 flex flex-row h-[60px] w-full md:w-2/12 membrane-btn-dark z-0 md:z-5 md:order-2">
        <img
          src="/images/navbar/membrane-dark.svg"
          class="absolute left-1/2 transform -translate-x-1/2 cursor-pointer focus:outline-0 z-5"
          @click="toggleMembraneOverlay()"
        >
        <MembraneOverlay
          :visible="membraneOverlayVisible"
          @ready="handleMembraneReady()"
          @membrane-hidden="emit('membrane-hidden')"
          @membrane-visible="emit('membrane-visible')"
          @data-facet-btn-click="handleDataFacetBtnClick()"
        />
      </div>
    </div>
  </div>

  <!--
  // Light navbar
  //------------------------------------------------------------------------------ -->

  <div
    id="lightNavbar"
    class="overflow-x-hidden md:overflow-x-visible opacity-1 relative z-10 w-full"
    :class="membraneOverlayVisible ? 'pointer-events-none' : ''"
  >
    <svg
      class="membrane-cutout"
      fill="none"
      height="60"
      viewBox="0 0 663 60"
      width="663"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m663 0h-331.25-331.25v60h259.996c10.394 0 18.437-8.8265 22.202-18.5 7.389-18.9833 26.45-32.5 48.8-32.5s41.411 13.5167 48.8 32.5c.108.2782.214.5573.317.8373 3.412 9.2811 11.087 17.6627 20.989 17.6627h261.396z"
        fill="#fff"
      />
    </svg>

    <div class="flex flex-col md:flex-row">
      <!-- Left of the membrane button -->
      <div class="flex flex-row w-full md:w-5/12 h-[60px] px-0 md:px-1 bg-white z-10 border-b md:border-0">
        <router-link
          v-for="(menuItem, index) in menuItems"
          :key="`light-menu-item-${index}`"
          :to="menuItem.to()"
          :class="[menuItem.isActive() ? 'bg-blue-50/50 text-blue-primary' : 'transition', 'justify-center w-1/3 md:w-auto px-[14px] h-full inline-flex align-items-center text-md text-content-dark-blue hover:bg-blue-50 hover:no-underline']"
        >
          {{ menuItem.title }}
        </router-link>
        <a
          href="https://university.stjude.cloud/docs/pecan/"
          target="_blank"
          class="justify-center w-1/3 md:w-auto px-[14px] h-full inline-flex align-items-center text-md text-content-dark-blue hover:no-underline hover:bg-blue-50 transition-all ease-in-out"
        ><span class="text-ellipsis overflow-hidden">Documentation</span></a>
      </div>

      <!-- Right of the membrane button -->
      <div class="flex flex-row w-full md:w-5/12 h-[60px] bg-white z-20 md:order-3">
        <NavSearch
          :loading="loadingResults"
          :tab="tab"
          :search-term="searchTerm"
          :results="results"
          @switch-tab="switchTabTo"
          @reset-search="resetSearch"
          @search="search"
          @open-membrane-overlay="openMembraneOverlay"
        />
      </div>

      <!-- Light membrane button -->
      <div class="relative flex flex-row h-[60px] w-full md:w-2/12 md:order-2 pb-32 md:pb-0">
        <div class="pulse mx-auto mt-3">
          <span class="ring ring-[#ececec]" />
          <span class="ring ring-[#ececec]" />
          <span class="ring ring-[#ececec]" />
        </div>

        <img
          ref="lightMembraneToggleBtnEl"
          src="/images/navbar/membrane-btn.svg"
          class="absolute top-[10px] md:top-[16px] left-1/2 transform -translate-x-1/2 grayscale opacity-10 cursor-pointer focus:outline-0 z-10 membrane-btn"
          @click="toggleMembraneOverlay()"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { gsap } from 'gsap';
import { datadogLogs } from '@datadog/browser-logs';
import { useAPI } from '@/api/api';
import MembraneOverlay from '@/layouts/navbar/MembraneOverlay';
import NavSearch from '@/components/NavSearch';

import { useSunburstStore } from '@/store/sunburst';
import { storeToRefs } from 'pinia';
const sunburstStore = useSunburstStore();
const { isMembraneOpen } = storeToRefs(sunburstStore);

// Emit events
const emit = defineEmits(['membrane-hidden', 'membrane-visible']);

const route = useRoute();
const router = useRouter();

const menuURLs = {
  'home': router.resolve({ name: 'home' }).href,
  'pie': router.resolve({ name: 'pie-home' }).href,
};

const menuItems = ref([
  {
    title: 'Home',
    to: () => menuURLs['home'],
    isActive: () => menuURLs['home'] === route.fullPath,
  },
  {
    title: 'PIE',
    to: () => menuURLs['pie'],
    isActive: () => route.fullPath.startsWith(menuURLs['pie']),
  },
]);

const membraneOverlayReady = ref(false);
const membraneOverlayVisible = ref(false);
const lightMembraneToggleBtnEl = ref(null);

//
// Lifecycle
//------------------------------------------------------------------------------

onMounted(async () => {
  // Pulsate rings around the toggle-membrane button
  gsap.to('#lightNavbar .ring', {
    scale: 1.75,
    opacity: 0,
    duration: 2,
    stagger: {
      each: 0.5,
      repeat: -1,
    },
  });
});

//
// Events
//------------------------------------------------------------------------------

// Switch between light & dark navbars when the membrane overlay is toggled
watch(
  () => membraneOverlayVisible.value, (visible) => {
    if (visible) {
      isMembraneOpen.value = true;
      gsap.to('#lightNavbar', { opacity: 0 });
      gsap.to('#darkNavbar', { opacity: 1 });
    } else {
      gsap.to('#lightNavbar', { opacity: 1 });
      gsap.to('#darkNavbar', { opacity: 0 }).eventCallback("onComplete", () => { isMembraneOpen.value = false });
    }
  },
);

// Close membrane overlay when navigating/route changes
watch(() => route.fullPath, () => {
  if (membraneOverlayVisible.value) {
    toggleMembraneOverlay();
  }
});

//
// Other events
//------------------------------------------------------------------------------

function handleMembraneReady() {
  // Remove pulsating rings and fade in the toggle-membrane button
  lightMembraneToggleBtnEl.value.classList.remove('grayscale');
  gsap.to(lightMembraneToggleBtnEl.value, {
    opacity: 1,
    duration: 0.5,
    onComplete() {
      gsap.to('#lightNavbar .ring', {
        opacity: 0,
        duration: 0.5,
        onComplete() {
          gsap.killTweensOf('#lightNavbar .ring');
          membraneOverlayReady.value = true;
        },
      });
    },
  });
}

function toggleMembraneOverlay() {
  if (membraneOverlayReady.value) {
    membraneOverlayVisible.value = !membraneOverlayVisible.value;
  }
}

function openMembraneOverlay() {
  if(!membraneOverlayVisible.value)
    membraneOverlayVisible.value = true;
}

function handleDataFacetBtnClick() {
  toggleMembraneOverlay();
}

//
// Search Functionality
//------------------------------------------------------------------------------

const loadingResults = ref(false);

const tab = ref('diagnosis');
const searchTerm = ref('');
const results = ref([]);

const switchTabTo = (newTab) => {
  resetSearch();
  tab.value = newTab;
};

const search = (searchInput) => {
  if (searchInput) {
    searchTerm.value = searchInput;
    loadingResults.value = true;
    if(tab.value === 'diagnosis') {
      const searchResults = Object.values(sunburstStore.sunburstData).filter(obj => obj.dx_full.toLowerCase().includes(searchInput.toLowerCase()));
      results.value = searchResults.slice(0, 10);
      loadingResults.value = false;
    } else {
      useAPI()
        .get(`/search/${tab.value}/${searchTerm.value}`)
        .then((resp) => resp.data)
        .then((resp) => {
          results.value = resp.data.items;

          const duplicateTexts = {};
          results.value.forEach((result) => {
            duplicateTexts[result.legacy_text] ? (duplicateTexts[result.legacy_text] += 1) : (duplicateTexts[result.legacy_text] = 1);
            result.duplicateIndex = duplicateTexts[result.legacy_text];
          });
        })
        .finally(() => {
          loadingResults.value = false;
        })
        .catch((err) => {
          datadogLogs.logger.error(`Error requesting /search/${tab.value}/${searchTerm.value}`, {}, err);
        });
    }
  } else {
    resetSearch();
  }
};

const resetSearch = () => {
  searchTerm.value = '';
  results.value = [];
};
</script>

<style lang="scss">
#lightNavbar .pulse {
  background-color: #fff;
  height: 86px;
  width: 86px;
  border-radius: 100%;
  position: relative;
  top: -5px;

  @screen md {
    top: 0;
  }
}

#lightNavbar .ring {
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: .8;
}

#lightNavbar .membrane-cutout {
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 60px;
  left: -50%;
  position: absolute;
  top: 111px;
  width: 200%;
  z-index: 5;

  @screen sm {
    height: 70px;
    left: -75%;
    width: 250%;
  }

  @screen md {
    height: 60px;
    left: 0;
    overflow-x: visible;
    top: 0;
    width: 100%;
  }
}

.membrane-btn {
  border-radius: 100%;
  box-shadow: 0px 2px 10px #1874DC, 0px 2px 10px #35A7D3, 0px 0px 10px #FFFFFF;
  transition: box-shadow ease-in-out .3s;
}

.membrane-btn:hover {
  box-shadow: 0px -2px 10px #1874DC, 0px 2px 10px #35A7D3, 0px 3px 10px #FFFFFF;
}

.membrane-btn-dark::after {
  bottom: 0;
  content: '';
  box-shadow: 0px -2px 10px #1874DC, 0px -2px 10px #35A7D3, 0px 3px 10px #FFFFFF;
  border-radius: 100%;
  height: 86px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 16px;
  width: 86px;
  z-index: -1;
  transition: opacity ease-in-out .3s;
}

.membrane-btn-dark:hover::after {
  opacity: 1;
}

</style>
