<template>
  <div
    id="donutCircle"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[285px] h-[285px] flex flex-col justify-center items-center opacity-0"
  >
    <div class="pulse opacity-10 mx-auto">
      <span class="ring ring-[#ececec]" />
      <span class="ring ring-[#ececec]" />
      <span class="ring ring-[#ececec]" />
      <span class="ring ring-[#ececec]" />
    </div>

    <div class="opacity-10 absolute flex flex-col items-center justify-center w-full h-full bg-white rounded-full">
      <!-- ... -->
    </div>

    <div class="absolute w-full h-full flex flex-col justify-center items-center font-bold leading-normal text-white text-[18px] opacity-60">
      <!-- Back button -->
      <svg
        v-if="sunburstSelection.diseaseHierarchy"
        width="51"
        height="12"
        viewBox="0 0 51 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3148 10H20.8898C22.4738 10 23.4528 9.12 23.4528 7.8C23.4528 6.942 22.9138 6.26 22.0338 5.941C22.7048 5.611 23.1118 5.039 23.1118 4.335C23.1118 3.125 22.1548 2.3 20.5818 2.3H17.3148V10ZM20.3948 3.587C21.0768 3.587 21.4948 3.994 21.4948 4.522C21.4948 5.05 21.0768 5.468 20.3948 5.468H18.9868V3.587H20.3948ZM20.6038 6.623C21.3298 6.623 21.7808 7.107 21.7808 7.679C21.7808 8.262 21.3408 8.735 20.6148 8.735H18.9868V6.623H20.6038ZM31.0159 10H32.7649L29.8719 2.3H27.8919L24.9989 10H26.6929L27.3199 8.218H30.3779L31.0159 10ZM27.7489 6.997L28.8379 3.906L29.9379 6.997H27.7489ZM38.1314 8.603C36.8884 8.592 35.9424 7.613 35.9424 6.15C35.9424 4.665 36.9104 3.697 38.1204 3.697C39.0114 3.697 39.6604 4.148 40.0454 4.984L41.5304 4.401C40.9034 2.982 39.7594 2.179 38.0984 2.179C35.9754 2.179 34.2374 3.752 34.2374 6.15C34.2374 8.548 35.9754 10.121 38.0874 10.121C39.7374 10.121 40.8704 9.296 41.5084 7.899L40.0674 7.338C39.6824 8.174 39.0224 8.603 38.1314 8.603ZM48.4434 10H50.5334L47.2884 5.644L50.3794 2.3H48.3774L45.3084 5.864V2.3H43.6254V10H45.3084V7.778L46.1554 6.854L48.4434 10Z"
          fill="white"
        />
        <path
          d="M0.647711 5.54633L5.84005 0.354254C6.09038 0.103921 6.49647 0.103921 6.74681 0.354254L7.35247 0.959915C7.60253 1.20998 7.6028 1.615 7.35354 1.8656L3.2384 5.9997L7.35327 10.1341C7.6028 10.3847 7.60227 10.7897 7.3522 11.0398L6.74654 11.6454C6.4962 11.8958 6.09012 11.8958 5.83978 11.6454L0.647711 6.45308C0.397378 6.20275 0.397378 5.79666 0.647711 5.54633Z"
          fill="white"
        />
      </svg>
    </div>
  </div>

  <div
    v-pre
    ref="sunburstContainerEl"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[432px] mx-auto"
  />

  <!-- Reset button -->
  <svg
    v-if="isResetVisible"
    class="absolute top-[110px] left-1/2 transform -translate-x-1/2 w-[55px] h-[12px] ml-[10px] cursor-pointer opacity-60"
    width="55"
    height="12"
    viewBox="0 0 55 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="resetSunburst()"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.61246 3.08317H4.83317V4.24984H0.749837V0.166504H1.9165V2.07421C2.92282 0.856302 4.36403 0.166504 5.99984 0.166504C9.2215 0.166504 11.8332 2.77818 11.8332 5.99984C11.8332 9.2215 9.2215 11.8332 5.99984 11.8332C2.77818 11.8332 0.166504 9.2215 0.166504 5.99984H1.33317C1.33317 8.57717 3.42251 10.6665 5.99984 10.6665C8.57717 10.6665 10.6665 8.57717 10.6665 5.99984C10.6665 3.42251 8.57717 1.33317 5.99984 1.33317C4.60356 1.33317 3.40607 1.96241 2.61246 3.08317Z"
      fill="white"
    />
    <path
      d="M19.2407 10H20.7707V7.26H21.8407L23.1307 9.33C23.4407 9.84 23.7507 10.02 24.4507 10.03C24.7007 10.03 24.8707 10.01 25.1307 9.98V8.83C25.0907 8.84 24.9007 8.84 24.8607 8.84C24.6207 8.84 24.4907 8.76 24.3607 8.58L23.3307 7C24.1507 6.63 24.6707 5.92 24.6707 5.11C24.6707 3.94 23.8207 3 22.2907 3H19.2407V10ZM22.1007 4.2C22.7707 4.2 23.1607 4.6 23.1607 5.16C23.1607 5.68 22.7907 6.13 22.0807 6.13H20.7707V4.2H22.1007ZM27.0473 10H32.2273V8.83H28.5773V6.99H31.3473V5.83H28.5773V4.21H32.1873V3H27.0473V10ZM36.5945 10.1C38.2645 10.1 39.3645 9.13 39.3645 7.91C39.3645 7.01 38.9145 6.38 37.4545 5.96L36.4045 5.66C35.7845 5.48 35.5045 5.24 35.5045 4.83C35.5045 4.4 35.8945 4.09 36.5945 4.09C37.1645 4.09 37.7845 4.37 38.3045 4.91L39.2645 3.95C38.6345 3.32 37.7445 2.9 36.5645 2.9C34.9245 2.9 33.9745 3.8 33.9745 4.97C33.9745 5.9 34.4645 6.57 35.7745 6.93L36.9045 7.24C37.6345 7.44 37.8145 7.73 37.8145 8.08C37.8145 8.57 37.3545 8.93 36.5945 8.93C35.8545 8.93 35.2045 8.61 34.6445 8.11L33.7245 9.1C34.4345 9.73 35.4045 10.1 36.5945 10.1ZM41.5668 10H46.7468V8.83H43.0968V6.99H45.8668V5.83H43.0968V4.21H46.7068V3H41.5668V10ZM48.304 4.27H50.594V10H52.124V4.27H54.404V3H48.304V4.27Z"
      fill="white"
    />
  </svg>
</template>

<script setup>
import { onMounted, ref, unref, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useSunburstStore } from '@/store/sunburst';
import * as d3 from 'd3';
import { color } from 'd3-color';
import { storeToRefs } from 'pinia';
import { gsap } from 'gsap';

const emit = defineEmits(['ready']);

const { isAuthenticated, isLoading } = useAuth0();

const sunburstStore = useSunburstStore();
const { sunburstSelection } = storeToRefs(sunburstStore);

const sunburstContainerEl = ref(null);
const isResetVisible = ref(false);
let resetSunburst = ref(() => {
});

//
// Lifecycle
//------------------------------------------------------------------------------

onMounted(async () => {
  // Pulse rings around the donut circle
  gsap.to('#donutCircle .ring', {
    scale: 1.75,
    opacity: 0,
    duration: 0.75,
    stagger: {
      each: 0.5,
      repeat: -1,
    },
  });

  sunburstStore.$subscribe((mutation) => {
    if (mutation.payload) {
      const { dataRequest } = mutation.payload;

      if (dataRequest && dataRequest.hasSucceeded && null !== dataRequest.response) {
        const { sunburstHierarchy } = sunburstStore;

        // Compute root selection stats
        let mutsigSamples,
          tsneSamples,
          variantsSamples,
          histSamples,
          samples,
          patients;

        mutsigSamples = tsneSamples = histSamples = variantsSamples = samples = patients = 0;

        sunburstHierarchy.children.forEach((child) => {
          mutsigSamples += child.mutsigSampleTotal;
          tsneSamples += child.tsneSampleTotal;
          histSamples += child.histSampleTotal;
          variantsSamples += child.variantsSampleTotal;

          samples += child.sampleTotal;
          patients += child.patientTotal;
        });

        sunburstStore.setTotalSamples(samples);

        sunburstStore.setSelection({
          mutsigSamples,
          tsneSamples,
          variantsSamples,
          histSamples,
          samples,
          patients,
        });

        buildSunburst(sunburstHierarchy);
        emit('ready');
      }
    }
  });

  // On mount, only load data if Auth0 is not in a loading state. Changes to
  // the isLoading value will trigger a watch function below.
  if (!unref(isLoading)) await loadSunburstData();
});

//
// Events
//------------------------------------------------------------------------------

watch(isLoading, async () => {
  // If we go from isLoading to !isLoading (e.g. loading is complete), load
  // the sunburst data.
  if (!unref(isLoading)) await loadSunburstData();
});

watch(isAuthenticated, async () => {
  // Reload the sunburst data when a user is authenticated.
  //
  // Like the React package issue linked below, there is a race condition where
  // the isAuthenticated value doesn't get set to true until a little after
  // the page is loaded isLoading is set to false.
  //
  // Link: https://github.com/auth0/auth0-react/issues/343
  if (unref(isAuthenticated)) await loadSunburstData();
});

async function loadSunburstData() {
  // To prevent a race condition with sunburst requests, if Auth0 is still
  // loading or a sunburst request is already in flight, delay the new request
  // by 250 ms.
  if (unref(isLoading) || sunburstStore.dataRequest.inFlight) {
    await new Promise(r => setTimeout(r, 250)); 
  }

  sunburstStore.requestData();
}

//
// Sunburst inner workings
//------------------------------------------------------------------------------

function buildSunburst(sunburstHierarchy) {
  // Prepare SVG workspace
  const width = 432;
  const radius = width / 6;

  const svg = d3
    .create('svg')
    .attr('viewBox', [0, 0, width, width]);

  const g = svg
    .append('g')
    .attr('transform', `translate(${width / 2},${width / 2})`);

  // Format data
  const partition = d3
    .hierarchy(sunburstHierarchy)
    .sum((d) => d.size)
    .sort((a, b) => b.size - a.size);

  const root = d3
    .partition()
    .size([2 * Math.PI, partition.height + 1])(partition)
    .each((d) => (d.current = d));

  // Calculate each arc
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    // .innerRadius(0) // Comment out to get a donut hole:
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  // Draw slices
  const path = g
    .append('g')
    .selectAll('path')
    .data(root.descendants().slice(1))
    .join('path')
    .attr('fill', (d) => {
      while (d.depth > 1) d = d.parent;
      return color(d.data.color);
    })
    .attr('fill-opacity', (d) =>
      arcVisible(d.current) ? 1 : 0,
    )
    .attr('d', (d) => arc(d.current))
    .style('stroke', d => arcVisible(d) ? 'transparent' : 'none');

  path
    .filter((d) => d.current)
    .style('cursor', 'pointer')
    .on('click', clickPath)
    .append('title')
    .text((d) => `${d.data.dx_full}`);

  // Draw labels
  const label = g
    .append('g')
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .style('user-select', 'none')
    .selectAll('text')
    .data(root.descendants().slice(1))
    .join('text')
    .style('font', '10px Inter, sans-serif')
    .style('font-weight', 'bold')
    .attr('fill', 'white')
    .attr('dy', '0.35em')
    .attr('fill-opacity', (d) => +labelVisible(d.current))
    .attr('transform', (d) => labelTransform(d.current))
    .text((d) => {
      if (d.data.name.length > 8) {
        return d.data.name.slice(0, 8) + '...';
      }

      return d.data.name;
    });

  const parent = g
    .append('circle')
    .datum(root)
    .attr('r', radius)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('click', clickPath);

  resetSunburst.value = () => clickPath(null, root);

  function clickPath(event, p) {
    isResetVisible.value = null !== p.parent;

    let disease = '';
    let diseaseHierarchy = '';
    let diseasePath = '';
    let mutsigSamples,
      tsneSamples,
      variantsSamples,
      histSamples,
      samples,
      patients;

    mutsigSamples = tsneSamples = histSamples = variantsSamples = samples = patients = 0;

    if (p.data.children) {
      p.data.children.forEach((child) => {
        mutsigSamples += child.mutsigSampleTotal;
        tsneSamples += child.tsneSampleTotal;
        histSamples += child.histSampleTotal;
        variantsSamples += child.variantsSampleTotal;

        samples += child.sampleTotal;
        patients += child.patientTotal;
      });
    }

    if (null !== p.parent) {
      isResetVisible.value = true;

      disease = p.data.dx_full;
      diseaseHierarchy = p.data.alias;
      diseasePath = p.data.diseasePath;

      mutsigSamples = p.data.mutsigSampleTotal;
      tsneSamples = p.data.tsneSampleTotal;
      histSamples = p.data.histSampleTotal;
      variantsSamples = p.data.variantsSampleTotal;

      samples = p.data.sampleTotal;
      patients = p.data.patientTotal;
    }

    sunburstStore.setSelection({
      disease,
      diseaseHierarchy,
      diseasePath,
      mutsigSamples,
      tsneSamples,
      variantsSamples,
      histSamples,
      samples,
      patients,
    });

    if (p.parent) {
      // Show the donut circle if the selection has a parent
      // and animate the pulsating ripples in and out

      gsap.to('#donutCircle .pulse', {
        opacity: 0.1,
      });

      gsap.to('#donutCircle', {
        duration: 1,
        opacity: 1,
      });

      setTimeout(() => {
        gsap.to('#donutCircle .pulse', {
          opacity: 0.01,
        });
      }, 350);
    } else {
      // Hide the donut circle if we're at the root level
      // and make sure the pulsating ripples are hidden too

      gsap.to('#donutCircle .pulse', {
        opacity: 0.01,
      });

      gsap.to('#donutCircle', {
        duration: 0.45,
        opacity: 0,
      });
    }

    parent.datum(p.parent || root);

    transitionPath(p);
  }

  function transitionPath(p) {
    root.each(
      (d) => {
        if (p.data.children || p.data.name !== d.data.name) {
          d.target = {
            x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
            y0: Math.max(0, d.y0 - p.depth),

            x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
            y1: Math.max(0, d.y1 - p.depth),
          };
        } else {
          // Make the last arc 360 degrees when selected and display it's label at the top of the ark
          // see labelTransform() for the label positioning
          d.target = {
            x0: 0, y0: 1, x1: 2 * Math.PI, y1: 2, labelTransform: false,
          };
        }
      },
    );

    const t = g.transition().duration(750);

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.

    path
      .transition(t)
      .tween('data', (d) => {
        const i = d3.interpolate(d.current, d.target);
        return (t) => (d.current = i(t));
      })
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || arcVisible(d.target);
      })
      .style('cursor', 'pointer')
      .attr('fill-opacity', (d) =>
        arcVisible(d.target) ? 1 : 0,
      )
      .attrTween('d', (d) => () => arc(d.current));

    label
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || labelVisible(d.target);
      })
      .transition(t)
      .attr('fill-opacity', (d) => +labelVisible(d.target))
      .attrTween('transform', (d) => () => labelTransform(d.current));
  }

  function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03 && !d.hideLabel;
  }

  function labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;

    if (d.labelTransform === false) {
      return `rotate(180) translate(0,110) rotate(180)`; // Display the label at the top of the full circle/arc
    }

    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }

  //
  // Finished, append to the DOM

  sunburstContainerEl.value.innerHTML = '';
  sunburstContainerEl.value.appendChild(svg.node());
}
</script>

<style>
#donutCircle .pulse {
  background-color: #fff;
  height: 285px;
  width: 285px;
  border-radius: 100%;
  position: relative;
}

#donutCircle .ring {
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: .8;
}
</style>
