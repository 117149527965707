<template>
  <div id="intercom" />
</template>

<script setup>
import { onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

const intercomId = process.env.VUE_APP_INTERCOM_WORKSPACE_ID;

const { user } = useAuth0();

onMounted(() => {
  let user = {};

  if (user?.email) {
    user = {
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
    };
  }

  // From intercom documentation
  window.intercomSettings = { app_id: intercomId, ...user };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + intercomId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
});
</script>
