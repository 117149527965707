import { h } from 'vue';
import { createRouter, createWebHistory, RouterView } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';

import { isFeatureEnabled, FeatureFlags } from "@/lib/FeatureFlags";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomePage'),
  },

  //
  // Pie
  //------------------------------------------------------------------------------

  {
    path: '/pie/jobs/:selectedTab',
    name: 'pie-jobs',
    beforeEnter: authGuard,
    component: () => import('../views/pie/PieJobs'),
  },

  {
    path: '/pie/job/:jobId(\\d+)',
    name: 'pie-job-variant-list',
    beforeEnter: authGuard,
    component: () => import('../views/pie/PieJobVariantList'),
  },

  {
    path: '/pie/accept-invite/:invitation/:uuid',
    name: 'pie-accept-invite',
    beforeEnter: authGuard,
    component: () => import('../views/pie/PieAcceptInvitation'),
  },

  {
    path: '/pie/job/:jobId(\\d+)/:variantId(\\d+)',
    name: 'pie-variant-page',
    beforeEnter: authGuard,
    component: () => import('../views/pie/PieVariantPage'),
  },

  {
    path: '/pie/submit-job',
    name: 'pie-submit-job',
    beforeEnter: authGuard,
    component: () => import('../views/pie/PieSubmitJob'),
  },

  {
    path: '/pie',
    name: 'pie-home',
    component: () => import('../views/pie/PieHome'),
  },

  //
  // Expression
  //------------------------------------------------------------------------------
  {
    path: '/expression/:selectedTab/:subtypeRoot?',
    name: 'expression-home',
    component: () => import('../views/expression/ExpressionHome'),
  },

  {
    path: '/expression/',
    redirect: to => 'expression/tsne/HM'
  },

  // Redirect old expression URLs
  {
    path: '/gene-expression-map/:diseaseHierarchy?',
    redirect: to => {
      return { name: 'expression-home', params: { diseaseHierarchy: to.params.diseaseHierarchy } }
    }
  },

  //
  // Histology
  //------------------------------------------------------------------------------

  {
    path: '/histology',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: ':diseaseHierarchy?',
        name: 'histology-home',
        component: () => import('../views/histology/HistologyHome'),
        props: true,
      },
      {
        path: 'sample/:sampleId',
        component: () => import('../views/histology/HistologySampleComparison'),
        name: 'sample-page',
      },
      ...(isFeatureEnabled(FeatureFlags.HISTOLOGY_DASHBOARD)
        ? [
            {
              path: "signin",
              name: "histology-signin",
              component: () => import("../views/histology/SignIn"),
              props: true,
            },
            {
              path: "dashboard",
              name: "histology-dashboard",
              beforeEnter: authGuard,
              component: () => import("../views/histology/DashBoard"),
              props: true,
            },
          ]
        : []),
    ],
  },

  //
  // Mutational Signatures
  //------------------------------------------------------------------------------

  {
    path: '/mutational-signatures/:diseaseHierarchy?',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'mutational_signature_home',
        component: () => import('../views/mutational-signatures/MutationalSigHome'),
        props: true,
      },
      {
        path: 'barplot',
        name: 'mutational_signature_barplot',
        component: () => import('../views/mutational-signatures/GroupDisplay'),
      },
      {
        path: 'sample/:sampleId',
        name: 'mutational_signature_sample',
        component: () => import('../views/mutational-signatures/MutationalSigSample'),
      },
    ],
  },

  //
  // Variants
  //------------------------------------------------------------------------------

  {
    name: 'variant_page',
    path: '/variants/:selectedTab/:variantId(\\d+)/:diseaseHierarchy?',
    component: () => import('../views/variants/VariantsHome.vue'),
  },
  {
    name: 'variants',
    path: '/variants/:selectedTab/:diseaseHierarchy?',
    component: () => import('../views/variants/VariantsHome.vue'),
  },

  //
  // Catchall/404
  //------------------------------------------------------------------------------

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
