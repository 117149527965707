<template>
  <svg
    width="677"
    class="hidden md:visible"
    height="677"
    viewBox="0 0 677 677"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M338.5 674C523.792 674 674 523.792 674 338.5C674 153.208 523.792 3 338.5 3C153.208 3 3 153.208 3 338.5C3 523.792 153.208 674 338.5 674Z"
      stroke="white"
      stroke-width="6"
    />
    <path
      d="M86.0722 150.628L88.6778 147.142C90.699 144.438 90.2328 141.133 87.6313 139.188C85.0297 137.244 81.7952 137.643 79.8314 140.27L77.1013 143.922L86.0722 150.628ZM81.2771 141.81C82.5416 140.118 84.5285 139.945 86.1561 141.162C87.7836 142.378 88.19 144.32 86.9351 145.999L85.8718 147.422L80.233 143.207L81.2771 141.81Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M96.2565 137.38L97.6591 135.634L93.43 132.238C91.933 131.035 90.2161 131.155 88.7534 132.976C87.1805 134.935 87.6044 136.732 88.6346 137.724L90.1141 136.264C89.5304 135.714 89.4518 134.994 90.0429 134.258C90.5939 133.572 91.3462 133.478 92.0573 134.049L92.2569 134.209L91.1686 136.229C90.0528 138.308 90.2908 139.341 91.3387 140.182C92.3367 140.984 93.7064 140.811 94.6982 139.576C95.3293 138.79 95.5787 137.739 95.483 136.759L96.2565 137.38ZM92.484 138.373C92.0598 138.032 92.1107 137.56 92.7001 136.392L93.3547 135.091L94.3652 135.903C94.4512 136.69 94.3474 137.407 93.8665 138.006C93.4257 138.555 92.8832 138.693 92.484 138.373Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M103.172 128.551L101.488 127.815C101.367 128.279 101.188 128.589 100.909 128.919C100.453 129.455 99.8639 129.606 99.2173 129.057L96.46 126.718L98.1576 124.717L96.7668 123.537L95.0692 125.538L93.2757 124.016L91.7955 125.761L93.5889 127.282L92.5848 128.466L93.9757 129.646L94.9798 128.462L98.0787 131.092C99.4817 132.282 100.857 132.022 102.057 130.607C102.627 129.936 102.988 129.213 103.172 128.551Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M107.272 124.482L108.766 122.814L104.727 119.194C103.296 117.913 101.576 117.94 100.017 119.68C98.3407 121.551 98.6672 123.368 99.6424 124.414L101.198 123.037C100.645 122.455 100.606 121.732 101.235 121.029C101.823 120.374 102.579 120.321 103.258 120.929L103.449 121.1L102.253 123.058C101.027 125.074 101.209 126.118 102.21 127.015C103.163 127.869 104.54 127.771 105.597 126.591C106.27 125.84 106.576 124.804 106.533 123.82L107.272 124.482ZM103.451 125.27C103.046 124.907 103.122 124.438 103.774 123.303L104.498 122.04L105.463 122.904C105.506 123.695 105.364 124.406 104.851 124.978C104.382 125.502 103.833 125.611 103.451 125.27Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M114.821 116.403L111.51 113.175L114.627 109.979L113.252 108.639L110.136 111.835L108.223 109.969L112.255 105.834L110.835 104.449L105.093 110.337L113.112 118.156L114.821 116.403Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M123.936 107.37L125.554 105.821L121.803 101.903C120.476 100.516 118.758 100.41 117.07 102.026C115.256 103.763 115.442 105.6 116.334 106.717L117.991 105.463C117.484 104.841 117.5 104.117 118.182 103.464C118.818 102.856 119.576 102.861 120.206 103.52L120.383 103.705L119.041 105.565C117.664 107.482 117.765 108.536 118.695 109.507C119.58 110.432 120.96 110.44 122.105 109.344C122.833 108.647 123.217 107.637 123.25 106.653L123.936 107.37ZM120.066 107.862C119.69 107.47 119.802 107.008 120.538 105.926L121.357 104.722L122.253 105.658C122.236 106.45 122.039 107.147 121.485 107.679C120.976 108.165 120.42 108.232 120.066 107.862Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M123.946 95.6032C122.169 97.2158 122.032 99.8031 123.762 101.711C125.493 103.619 128.092 103.745 129.882 102.122C131.209 100.918 131.538 99.2801 131.001 97.7361L129.12 98.4489C129.364 99.2649 129.306 99.987 128.571 100.654C127.706 101.438 126.421 101.308 125.421 100.206C124.454 99.1393 124.427 97.8241 125.304 97.0286C126.015 96.3835 126.762 96.3972 127.527 96.7402L128.421 94.8921C126.927 94.1738 125.285 94.3884 123.946 95.6032Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M138.225 92.4008L136.43 92.7125C136.49 93.5231 136.221 94.1941 135.588 94.7341C134.7 95.4922 133.558 95.4357 132.598 94.656L137.09 90.8241C136.946 90.6312 136.844 90.4869 136.699 90.3165C135.152 88.5028 132.805 87.8969 130.797 89.6104C129.056 91.0953 128.819 93.6528 130.481 95.6004C132.215 97.6331 134.881 97.6515 136.658 96.1353C137.887 95.0865 138.369 93.7288 138.225 92.4008ZM131.906 90.9353C132.709 90.25 133.743 90.3773 134.502 91.1184L131.666 93.538C131.073 92.5717 131.188 91.548 131.906 90.9353Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M145.585 88.1295L144.068 87.0925C143.863 87.5259 143.629 87.7969 143.293 88.0684C142.746 88.511 142.139 88.549 141.606 87.8894L139.333 85.0771L141.374 83.4277L140.227 82.0091L138.186 83.6585L136.708 81.8292L134.929 83.2674L136.407 85.0966L135.2 86.0722L136.346 87.4908L137.553 86.5153L140.108 89.676C141.265 91.1071 142.664 91.1076 144.107 89.941C144.792 89.3879 145.281 88.7457 145.585 88.1295Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M145.537 87.235C146.843 87.2831 147.995 86.9456 149.138 86.0704C150.828 84.7772 151.234 83.0963 150.262 81.8257C149.523 80.86 148.635 80.6734 146.814 81.3215L145.627 81.7459C144.993 81.9696 144.625 81.9892 144.372 81.6588C144.1 81.3031 144.223 80.8059 144.757 80.3975C145.354 79.9404 146.143 79.76 147.097 79.8755L147.281 78.0625C145.968 77.9792 144.896 78.236 143.778 79.0917C142.278 80.2391 141.801 81.8534 142.783 83.1367C143.522 84.1024 144.539 84.3518 146.178 83.7022L147.383 83.2233C148.046 82.9577 148.42 82.9732 148.683 83.3163C148.975 83.6975 148.842 84.262 148.169 84.7774C147.584 85.2247 146.784 85.4948 145.695 85.4414L145.537 87.235Z"
      fill="white"
      fill-opacity="0.1"
    />
  </svg>
</template>
