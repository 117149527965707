<template>
  <button
    class="relative btn btn-outline-light login-btn"
    @click="handleLogin"
  >
    {{ isLoading ? 'Loading...' : 'Sign In' }}

    <span
      v-if="isLoading"
      class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1"
    >
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" />
      <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500" />
    </span>
  </button>
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { isLoading, loginWithRedirect } = useAuth0();

const handleLogin = async () => {
  await loginWithRedirect({
    prompt: "login",
    appState: {
      target: window.location.pathname,
    },
  });
};
</script>