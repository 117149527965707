import { defineStore } from 'pinia';
import { useSunburstAPI } from '@/api/sunburst';

const initSunburstSelection = {
  disease: '',
  diseaseHierarchy: '',
  diseasePath: '',
  samples: 0,
  patients: 0,
};

export const useSunburstStore = defineStore('sunburst', {
  state: () => ({
    dataRequest: {},
    sunburstData: {},
    sunburstHierarchyMemo: null,
    totalSamples: 0,
    sunburstSelection: {
      ...initSunburstSelection,
    },
    isMembraneOpen: false,
  }),
  getters: {
    sunburstHierarchy: (state) => {
      const { dataRequest } = state;

      if (dataRequest.response) {
        const { sunburst_data: sunburstData } = dataRequest.response.data.data;

        const childArcs = {
          ST: { name: 'ST', alias: 'ST', color: '#F5AC0A', children: [] },
          HM: { name: 'HM', alias: 'HM', color: '#D01947', children: [] },
          BT: { name: 'BT', alias: 'BT', color: '#35A7D3', children: [] },
          GCT: { name: 'GCT', alias: 'GCT', color: '#858585', children: [] },
        };

        // Sets order of childArc rendering
        const sunburstHierarchy = {
          name: 'root',
          children: [childArcs.BT, childArcs.ST, childArcs.HM, childArcs.GCT],
        };

        let maxSampleTotal = 0;

        // Updates the hierarchy data structure to a form suitable for a d3.partition layout
        const setHierarchyNode = (disease, node, parent) => {
          if (!disease.length) {
            const {
              mutsig_total_subject_count: mutsigPatientTotal,
              mutsig_total_sample_count: mutsigSampleTotal,
              tsne_total_subject_count: tsnePatientTotal,
              tsne_total_sample_count: tsneSampleTotal,
              hist_total_subject_count: histPatientTotal,
              histology_total_sample_count: histSampleTotal,
              variants_total_subject_count: variantsPatientTotal,
              variants_total_sample_count: variantsSampleTotal,
              total_subject_count: patientTotal,
              total_sample_count: sampleTotal,
              dx_full,
            } = sunburstData[parent.alias];

            maxSampleTotal = Math.max(maxSampleTotal, sampleTotal);

            parent.mutsigPatientTotal = mutsigPatientTotal;
            parent.mutsigSampleTotal = mutsigSampleTotal;
            parent.tsnePatientTotal = tsnePatientTotal;
            parent.tsneSampleTotal = tsneSampleTotal;
            parent.histPatientTotal = histPatientTotal;
            parent.histSampleTotal = histSampleTotal;
            parent.variantsPatientTotal = variantsPatientTotal;
            parent.variantsSampleTotal = variantsSampleTotal;
            parent.sampleTotal = sampleTotal;
            parent.patientTotal = patientTotal;
            parent.dx_full = dx_full;
          } else {
            const name = disease.shift();
            const alias = parent && parent.alias ? parent.alias + '|' + name : name;

            if (!(alias in childArcs)) {
              childArcs[alias] = {
                name,
                alias,
                dx_full: node.dx_full,
                diseasePath: node.ancestor_dx_name,
                parent: parent,
              };
              if (!('children' in parent)) parent.children = [];
              parent.children.push(childArcs[alias]);
            }

            setHierarchyNode(disease, node, childArcs[alias]);
          }
        };

        for (let disease in sunburstData) {
          setHierarchyNode(disease.split('|'), sunburstData[disease]);
        }

        // Only the innermost child nodes should specify a .size property;
        // D3 will automatically calculate all parent arc sizes based on those
        const ensureArcSize = (node, parent) => {
          if (!node.children) {
            // Ensure a minimum node size to make sure every slice is visible
            const maxSiblingSampleTotal = Math.max(...parent.children.map((child) => child.sampleTotal));
            node.size = Math.max(node.sampleTotal, maxSiblingSampleTotal * 0.02, 75);

            // These need to be bumped up even more to be visible (they don't have siblings or are too small)
            if (parent && ['MNGT'].includes(parent.name)) {
              node.size *= 3;
            }

            // GCT is a special case where it really needs to be bumped up
            if (parent && 'GCT' === parent.name) {
              node.size = node.sampleTotal * Math.ceil(maxSampleTotal * 0.01);
            }

            return;
          }

          node.children.forEach((child) => {
            ensureArcSize(child, node);
          });
        };

        ensureArcSize(sunburstHierarchy);

        state.sunburstData = sunburstData;
        state.sunburstHierarchyMemo = sunburstHierarchy;
      }

      return state.sunburstHierarchyMemo;
    },
    diseaseOptions(state) {
      if (null === state.sunburstHierarchy) {
        return [];
      }

      const mapDiseases = (diseases) => {
        return diseases.map((disease) => {
          return {
            ...disease,
            value: disease.name,
            text: disease.dx_full,
            subtypes: disease.children ? mapDiseases(disease.children) : [],
          };
        });
      };

      const filteredDiseases = state.sunburstHierarchy.children?.filter((disease) => disease.variantsSampleTotal > 0);

      return mapDiseases(filteredDiseases);
    },
    genomePaintDiagnosisGroupOptions(state) {
      return Object.values(state.sunburstData)
        .filter((node) => node.dx_level === 2)
        .map((node) => node.dx_short);
    },
    genomePaintDiagnosisOptions(state) {
      return Object.values(state.sunburstData)
        .filter((node) => node.dx_level === 3 || node.dx_level === 4)
        .map((node) => node.dx_short);
    },
  },
  actions: {
    requestData() {
      return useSunburstAPI().requestData();
    },
    setTotalSamples(totalSamples = 0) {
      this.totalSamples = totalSamples;
    },
    setSelection(selection = {}) {
      this.sunburstSelection = {
        ...initSunburstSelection,
        ...selection,
      };
    },
  },
});
