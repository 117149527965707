<template>
  <div
    v-if="hasLayout"
    class="flex-column flex h-full"
  >
    <OmniBar />

    <main
      id="main"
      :class="['flex-column relative flex flex-1', membraneOverlayVisible ? 'overflow-hidden' : '']"
    >
      <NavBar
        @membrane-hidden="membraneOverlayVisible = false"
        @membrane-visible="membraneOverlayVisible = true"
      />

      <div class="min-h-[800px] h-full">
        <router-view :key="$route.fullPath" />
      </div>
    </main>

    <AppFooter />
  </div>

  <router-view
    v-else
    :key="$route.fullPath"
  />
  <IntercomButton />
</template>

<script setup>
import _ from 'lodash';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import AppFooter from '@/components/AppFooter';
import IntercomButton from '@/components/IntercomButton';
import NavBar from '@/layouts/NavBar';
import OmniBar from '@/layouts/OmniBar.vue';

const route = useRoute();

const hasLayout = ref(false !== _.get(route, 'matched[0].meta.layout', null));

const membraneOverlayVisible = ref(false);
</script>

<style>
@import '~@stjudecloud/theme/dist/css/stjude-cloud-theme.min.css';

.splash {
  background: url("./assets/app/splash.svg") no-repeat scroll 50% -100px;
  background-size: cover;
}
</style>
